import * as React from "react"
import { graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import moment from "moment"
import Layout from "../components/layout"
import SEO from "../components/seo"

const BlogPost = ({ data, pageContext }) => {
  const { changelog, next: nextPost } = pageContext
  const { mdx, markdownRemark } = data

  // fallback to markdownRemark (almost certainly a bug with a plugin/gatsby)
  // - sometimes the mdx key doesn't show up in the queried data...
  // additionally it also only happens when using the Gatsby router to
  // navigate to a blogPage, but not when you visit the page directly.

  const getContentFrom = mdx || markdownRemark

  const { frontmatter, body } = getContentFrom
  const {
    title,
    date,
    updated = false,
    category = "uncategorized",
    progress = "work in progress",
    certainty = 5,
    importance = 5,
    page = false,
    excerpt = "",
  } = frontmatter

  const constructHeader = () => {
    if (page) {
      return <span></span>
    }

    return (
      <div className="post-meta">
        <div className="top">
          <div className="item">
            {moment(date).fromNow()} in <u>{category}</u>
          </div>
          <div className="item">
            {updated && `. Updated ${moment(updated).fromNow()}.`}
          </div>
        </div>
      </div>
    )
  }

  const constructChangelog = () => {
    if (!changelog) return <span></span>

    return (
      <div className="post-changelog">
        <div className="title">Changes to this page</div>
        {changelog.map(log => (
          <div className="log" key={log.hash}>
            <span className="time">{moment(log.date).format("MMM Do YY")}</span>{" "}
            {log.message}
          </div>
        ))}
      </div>
    )
  }
  return (
    <Layout>
      <SEO
        title={title}
        description={excerpt}
        category={category}
        date={date}
      />
      <div className="post-container">
        <div className="post-header">
          {constructHeader()}
          <h1 className="post-title">{title}</h1>
        </div>
        <article id="markdownBody" className="post-content">
          <MDXRenderer>{body}</MDXRenderer>
        </article>
        {constructChangelog()}
      </div>
    </Layout>
  )
}

export const query = graphql`
  query ($slug: String) {
    mdx(frontmatter: { slug: { eq: $slug } }) {
      frontmatter {
        title
        date(formatString: "MMMM D, YYYY")
        updated(formatString: "MMMM D, YYYY")
        slug
        page
        category
      }
      body
      id
    }
  }
`

export default BlogPost
